<div class="modal-confirmation">
	<div class="modal-confirmation__container">
		<h3 class="jbl-heading-3 jbl-600 modal-confirmation__container-header">Switch Role?</h3>
		<h5 class="jbl-heading-5 modal-confirmation__container-text">You are about to change your role to {{ roleOption.label }}</h5>
	</div>
	<div class="footer">
		<button type="button" data-cy="cancel-change" class="jbl-btn jbl-btn-sm jbl-btn-secondary cancel-btn" (click)="activeModal.dismiss()">
			Cancel
		</button>
		<button
			type="button"
			data-cy="change-role"
			class="jbl-btn jbl-btn-sm jbl-btn-primary jbl-mg-l-12 continue-btn"
			(click)="activeModal.close(roleOption.value)">
			Change Role
		</button>
	</div>
</div>
