/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, computed, inject } from '@angular/core';
import { Router } from '@angular/router';
import { FEEDBACK_TOAST } from '@constants/feedback.constant';
import { MiRoutes } from '@core/configs/routes';
import { ConfigService } from '@core/services/config/config.service';
import { DuplicateOptionsInput, Quote, QuoteStatus, QuotesTableSearchParams, UserOwnershipInviteParams } from '@interfaces/quote.interface';
import { JblToastService } from '@jbl-pip/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { patchState, signalState } from '@ngrx/signals';
import { FeedbackService } from '@services/feedback.service';
import { QuotesModalService } from '@services/quotes-modal.service';
import { IServerSideGetRowsParams } from 'ag-grid-enterprise';
import { Observable, filter, map, of, switchMap, tap } from 'rxjs';
import { DUPLICATE_QUOTE_FAIL, DUPLICATE_QUOTE_SUCCESS } from 'src/app/modules/quote-details/quote-details.constant';
import { QuoteWithAttributes } from 'src/app/modules/quotes/components/quotes-table/quotes-table.interface';
import { QuoteCreateParams, QuotesDataService } from '../services/quotes-data-service';

@Injectable({ providedIn: 'root' })
export class QuotesStateService {
	private readonly modalService = inject(QuotesModalService);
	private readonly toastService = inject(JblToastService);
	private readonly feedbackService = inject(FeedbackService);
	private readonly configService = inject(ConfigService);
	private readonly quotesApi = inject(QuotesDataService);
	private readonly router = inject(Router);

	readonly state = signalState({
		currentQuoteId: '' as string,
		quotes: [] as Quote[],
		loading: false
	});

	// selectors
	readonly currentQuoteId = computed(() => this.state.currentQuoteId());

	readonly currentQuote = computed(() => {
		const currentQuotes = this.state.quotes();

		return currentQuotes.find(q => q.mi6_quote_id === this.currentQuoteId());
	});

	setCurrentQuoteId(quoteId?: string) {
		patchState(this.state, { currentQuoteId: quoteId });
	}

	setQuote(quote: Quote) {
		const currentQuotes = this.state.quotes();
		const withNew = currentQuotes.concat(quote);

		patchState(this.state, { quotes: withNew });
	}

	updateLocalQuote(quoteId: string, newQuoteValue: Partial<Quote>) {
		const currentQuotes = this.state.quotes();
		const mapped = currentQuotes.map((quote: Quote) => {
			if (quoteId === quote.mi6_quote_id) {
				return {
					...quote,
					...newQuoteValue
				};
			}

			return quote;
		});

		patchState(this.state, { quotes: mapped });
	}

	getQuoteStatus(quoteId: string): Observable<QuoteStatus> {
		return this.quotesApi.getQuoteStatus(quoteId).pipe(map((res: Quote) => res.status));
	}

	openFeedbackModal() {
		this.modalService
			.openFeedbackModal()
			.closed.pipe(
				filter(feedback => !!feedback),
				switchMap(feedback => {
					const currentUser = this.configService.currentUser();

					return this.feedbackService.sendFeedback({
						authorRole: currentUser ? currentUser.roles.join(',') : 'Unknown role',
						authorEmail: currentUser ? currentUser.email : '',
						text: feedback
					});
				})
			)
			.subscribe(() => this.toastService.success(FEEDBACK_TOAST.FB_REQUEST_SUCCESS));
	}

	createQuote(params: QuoteCreateParams) {
		let processingRef: NgbModalRef | null = null;

		of(params)
			.pipe(
				tap(() => {
					processingRef = this.modalService.openProcessingModal();
				}),
				switchMap(params => this.quotesApi.createQuote(params)),
				tap(quote => {
					// Move it out side when quotes list page will be implemented
					if (processingRef) {
						processingRef.close();
					}

					const currentUser = this.configService.currentUser();

					this.configService.updateOwnershipLocally(quote.mi6_quote_id, [currentUser!.username], 'owns');
					this.setCurrentQuoteId(quote.mi6_quote_id);
					this.router.navigateByUrl(`${MiRoutes.quotes.url}/` + quote.mi6_quote_id);
				})
			)
			.subscribe();
	}

	deleteQuote(quoteId: string) {
		return this.quotesApi.deleteQuote(quoteId);
	}

	checkForRawPartsExistence(partIds: string[] | null, quoteId: string) {
		return this.quotesApi.checkForRawPartsExistence(partIds, quoteId);
	}

	duplicateQuote(options: DuplicateOptionsInput, quoteId: string) {
		this.quotesApi.duplicateQuote(options, quoteId).subscribe(({ quoteId, error }) => {
			if (error) {
				this.toastService.danger(DUPLICATE_QUOTE_FAIL);
			} else if (quoteId) {
				const currentUser = this.configService.currentUser();

				this.configService.updateOwnershipLocally(quoteId, [currentUser!.username], 'owns');
				this.setCurrentQuoteId(quoteId);

				// We should use special provider withRouterConfig( onSameUrlNavigation: 'reload' }) or custom router reuse strategy
				// But it doesn't work for some reason.
				this.router.routeReuseStrategy.shouldReuseRoute = () => false;
				this.router.navigateByUrl(`${MiRoutes.quotes.url}/` + quoteId);
				this.toastService.success(DUPLICATE_QUOTE_SUCCESS);
			}
		});
	}

	closeQuote(quoteId: string) {
		this.quotesApi.closeQuote(quoteId).subscribe((updatedQuote: Quote) => {
			this.updateLocalQuote(quoteId, updatedQuote);
		});
	}

	inviteOwners(usersData: UserOwnershipInviteParams[]) {
		return this.quotesApi.inviteOwnersToQuote(this.state.currentQuoteId(), usersData);
	}

	inviteContributors(usersData: UserOwnershipInviteParams[]) {
		return this.quotesApi.inviteContributorsToQuote(this.state.currentQuoteId(), usersData);
	}

	loadAllQuotes() {
		patchState(this.state, { loading: true });

		this.quotesApi.getQuotes().subscribe(() => {
			const mapped = this.state.quotes().map(quote => {
				const scaIsRunning = quote.sca_run_status === 'Processing';

				return { ...quote, status: scaIsRunning ? quote.sca_run_status : quote.status };
			}) as Quote[];

			patchState(this.state, { quotes: mapped });
		});
	}

	loadAgQuotes(params: IServerSideGetRowsParams<QuoteWithAttributes>, searchParams: QuotesTableSearchParams | null) {
		return this.quotesApi.getAgQuotes(params, searchParams).pipe(
			map(({ success, rows, total }) => ({
				success,
				rows,
				total
			}))
		);
	}

	updateQuote({ quoteId, quote }: any) {
		this.quotesApi.updateQuote(quoteId, quote).subscribe(quote => {
			this.updateLocalQuote(quote.mi6_quote_id, quote);
		});
	}
}
